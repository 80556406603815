.mobile-sign-in-modal {
    width:80vw;
    padding-top: 15px;
    position: fixed;
    z-index: 40;
    top: 10vh;
    left: 10vw;
    background: #fff;
    box-shadow: 4px 3px 13px 0px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    text-align: center;
}

.mobile-mobile-header {
    margin-top: 10px;
}

.mobile-sign-in-modal h1 {
    color: #444;
    margin-top: 15px;
}

.mobile-sign-in-modal p:first-of-type {
    color: orangered;
}

.mobile-modal-exit {
    position: absolute;
    top: 5px;
    right: 5px;
    font-weight:lighter;
    color: red;
}

.mobile-form-group input {
    width: 100%;
    padding: 15px 10px;
    border-radius: 5px;
    border: 1px #ccc solid;
    margin-top: 20px;
}

.form-group input:focus {
    outline: orangered;
    border: 1px orangered solid;
}

#submit-btn {
    margin-top: 30px;
    width: 60%;
    border-radius: 30px;
    padding: 10px 5px;
    border: none;
    background: orangered;
    color: #fff;
}