.header {
    background-color: #fff;
    padding: 15px 30px !important;
    margin-top: 0;
    position: sticky;
    top: 0;
    z-index: 1001;
    box-sizing: border-box;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    width: 100%;
}

.navlinks {
    display: flex;
    justify-content: flex-end;
}

.brand-name {
    color: orangered !important;
    font-family: cursive;
}

.search-icon {
    align-self: center;
    margin-right: 10px;
}

.dropdown a {
    color: #444;
}

.nav a {
    color: #444;
}
.navlinks-item {
    margin-right: 25px;
}

.navlinks-item:hover {
    background-color: rgba(0, 0, 0, 0.2);
    border-bottom: 2px orangered solid;
}

.color-red {
    color: #444;
}

.color-red:hover {
    color: orangered;
}

.navlinks-item.cart {
    border-bottom: none;
    background-color: #fff;
    position: relative;
}

.cart-length {
    position: absolute;
    color: #fff;
    background-color: orangered;
    border-radius: 100%;
    padding: 10px;
    transform: translate(-10px, -3px) !important;
}

.account-btn {
    padding: 5px;
    border: 1px orangered solid;
    border-radius: 20px;
    background-color: #fff;
    color: #444;
}

.account-btn:hover {
    color: #fff;
    background-color: orangered;
}

.user-avatar {
    background-color: orangered;
    color: #fff;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    font-size: 32px;
    text-align: center;
    position: relative;
}

.user-menu {
    position: absolute;
    right: 10px;
    top: 40px;
    width: 250px;
    height: 250px;
    background-color: #fff;
    color: #555;
    border-radius: 10px;
    font-size: 16px;
    display: none;
    z-index: 1200;
    padding-top: 10px;
}

.user-avatar:hover .user-menu {
    display: block;
}

.user-menu>div {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #aaa solid;
    padding: 10px;
}

.user-menu .user-avatar {
    background-color: orangered;
    color: #fff;
    border-radius: 110%;
    width: 40px;
    height: 40px;
    font-size: 25px;
    text-align: center;
}

.user-menu * {
    margin-bottom: 0;
}

.user-menu *:not(:first-child, :last-child) {
    text-align: left;
    padding: 10px;
}

.user-menu *:not(:first-child, :last-child):hover {
    background-color: #f4f4f4;
}

.user-menu a {
    text-decoration: none;
    color: #555;
}

.log-out-btn {
    padding: 10px 20px;
    background-color: orange;
    border: 1px #fff solid;
    color: #fff;
    border-radius: 10px;
}

.log-out-btn:hover {
    background-color: gold;
}

.search {
    display: block;
    position: relative;
}

.normal-search {
    background-color: #fff;
    margin-top: 5px;
    position: relative;
}

.search .searchArea {
    display: flex;
    justify-content: center;
    position: relative;
    width: 30vw;
    margin: 0 10px;

}

.normal-search  .searchArea {
    display: flex;
    justify-content: center;
    position: relative;
    width: 90vw;
    margin: 0 10px;
}

.search >.searchArea>.input {
    height: 40px;
    width: 100%;
    border: 1px #0f729c solid;
    border-radius: 15px;
    padding: 5px 10px;
    text-indent: 30px;
}

.search .searchArea .input:focus {
    border: 2px orangered solid;
    outline: orangered;
}

.searchArea .fa-magnifying-glass {
    position: absolute;
    top: 10px;
    left: 10px;
}

.cart-icon {
    background-color: #fff;
    padding: 10px;
    border: none;
  }