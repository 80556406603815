.mobile-result,
.no-match-mobile {
    width: 80vw;
    padding: 10px;
    background-color: #fff;
    color: #333;
    position: absolute;
    top: 110px;
    left: 10vw;
    z-index: 5000;
    border-radius: 10px; 
    box-shadow: 4px 3px 13px 0px rgba(0, 0, 0, 0.75);
}

.mobile-result .search-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    height: 80px;
    overflow: hidden;
    padding-top: 10px;
    padding-left: 10px;
}

.mobile-result .search-item>img {
    width: 50px;
    height: 50px;
}

.mobile-result .search-item>div {
    padding-left: 10px;
    margin: 0;
}

.mobile-result a.result-link {
    color: #333 ;
    font-size: 16px ;
    text-decoration: none;
}