.search-result,
.no-match {
    width: 60vw;
    padding: 10px;
    background-color: #fff;
    color: #333;
    position: absolute;
    top: 20vh;
    left: 20vw;
    z-index: 1000;
    border-radius: 10px;
    box-shadow: 4px 3px 13px 0px rgba(0, 0, 0, 0.75);
}

.search-result .search-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    height: 120px;
    overflow: hidden;
    padding-top: 10px;
    padding-left: 10px;
}

a.result-link:not(:first-of-type)>.search-item {
    border-top: 2px #aaa solid;
}

.search-result .search-item>img {
    width: 75px;
    height: 75px;
}

.search-result .search-item>div {
    padding-left: 10px;
    margin: 0;
}

.search-result a.result-link {
    color: #333;
    font-size: 16px;
    text-decoration: none;
}

a.result-link:hover {
    background-color: rgba(255, 255, 255, 0.5) !important;
}

a.result-link.search-item~p {
    text-overflow: ellipsis;
}

@media(min-width: 769px) and (max-width: 1100px) {

    .search-result,
    .no-match {
        top: 30vh;
    }

}