.modal-container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal-container * {
    z-index: 100;
}

.confirm-modal {
    position: fixed;
    top: 30%;
    left: 35%;
    width: 400px;
    height: 250px;
    background-color: #fff;
    text-align: center;
    padding-top: 40px;

}

.confirm-modal>h5 {
    margin-bottom: 40px;
}


@media(max-width: 768px) {
    .confirm-modal {
        left: 10%;
        width: 80%;
    }
}