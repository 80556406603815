* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


.body {
  background-color: #f4f4f4;
  padding-bottom: 40px;
}

.category-title {
  text-align: center;
  margin: 15px;
  position: relative;
}

.category-title::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 50%;
  transform: translateY(10px);
  background-color: orangered;
  right: 25%;

}

.all-products {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.product-card {
  width: 250px;
  height: 500px;
  margin-top: 40px;
  position: relative;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(7.5px);
  box-shadow: 4px 3px 13px 0px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.product-card:hover {
  transition: all 0.5s ease;
  transform: scale(1.1, 1.1);
}

.product-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.product-card * {
  z-index: 1000;
  position: relative;
}

.product-card>img {
  width: 100%;
  height: 250px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

}

.product-card a {
  text-decoration: none;
}

.card-title {
  height: 120px;
  text-align: center;
  overflow: hidden;
}

.card-title h5 {
  color: #fff;
}

.card-btn {
  padding: 10px 5px;
  color: #fff;
  background-color: inherit;
  width: 120px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 2px #fff solid;
}

.card-add-cart-btn {
  padding: 10px 5px;
  color: #fff;
  background-color: green;
  width: 120px;
  display: block;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  border: none;
}

.card-btn:hover {
  background: rgba(255, 255, 255, 0.7);
  color: rgb(221, 2, 25);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(7.5px);
  border: none;

}

.card-add-cart-btn:hover {
  background-color: rgba(0, 255, 0, 0.75);
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.socials {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.socials .footer-icon {
  margin-right: 20px;
}

.socials .footer-icon:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

.footer-navs {
  margin-bottom: 10px;
}

.footer-nav {
  margin-right: 20px;
  text-decoration: none;
}

.footer-nav:hover {
  color: darkblue;
}

.promo {
  text-align: center;
  background-color: #b70000;
  color: #fff;
}


.breadcrumb {
  padding-left: 10px !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.breadcrumb>.breadcrumb-item>a {
  text-decoration: none;
  color: #b70000;
  font-size: 16px;
  font-weight: bolder;
}

.row:not(:first-of-type) {
  border-top: 3px #aaa solid;
}

.quotation-link-btn {
  text-align: center;
}

.back-btn {
  background-color: #b70000;
  color: #fff;
  width: 100px;
  padding: 15px 10px;
  font-weight: 500;
  border: none;
  margin-right: 15px;
}

.print-btn {
  background-color: orangered;
  color: #fff;
  width: 100px;
  padding: 15px 10px;
  font-weight: 500;
  border: none;
}

.floating-btn {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 250px;
  height: 60px;
  background-color: #25D366;
  font-weight: 500;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: larger;
  padding: 15px 10px;
  box-shadow: 4px 3px 13px 0px rgba(0, 0, 0, 0.75);
}

.floating-btn:hover {
  background-color: green;
}

.mobile-floating-btn {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 200px;
  height: 40px;
  background-color: #25D366;
  font-weight: 500;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: larger;
  padding: 5px 15px;
  box-shadow: 4px 3px 13px 0px rgba(0, 0, 0, 0.75);
}

.spinner-border {
  display: block !important;
  margin: 30px auto;
}

@media(max-width: 768px) {
  .all-products {
    display: grid;
    grid-template-columns: auto auto;
    gap: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .product-card {
    width: 150px;
    height: 260px;
    background: #fff;
    border: none;
  }

  .product-card::before {
    content: "";
    background-color: #fff;
    border-radius: none;
  }

  .product-card>img {
    width: 100%;
    height: 150px;
  
  }

  .card-title {
    height: 50px;
    color: #555;
  }

  .card-btn {
    width: 100px;
    border: none;
    background-color: #555;
    color: #fff;
    margin-top: 5px;
  }

  .card-btn:hover {
    background-color: green;
    color: #fff;
    border: none;
  }


}